import React, { useEffect, useRef, useState } from "react";
import useData from "../../../redux/customHook/useData";
import { useNavigate } from "react-router-dom";
import apiServices from "../../../../Service/apiService";
import html2canvas from "html2canvas";
import EyePrescriptionDownload from "./eyePrescriptionDownload";
import { formatDateToDDMMYY, formatDateToDDMMYYEPRESCRPITON } from "../../../specialMethod/getBetweenDate";

const EpresciptionDownload = ({
  doctorDetails,
  patientData,
  reset,
  prescriptionPhotoURLs,
  prescriptionResult,
  eyeData,
  selectedDate
}) => {
  const { data } = useData();
  const [prescriptionImage, setPrescriptionImage] = useState(null);
  // Map clinic locations to their IDs
  const clinicLocationToIdMap = data?.clinicDtos?.reduce((map, clinic) => {
    map[clinic.location] = clinic.id;
    return map;
  }, {});
  // Set prescription image based on clinic ID
  useEffect(() => {
    if (clinicLocationToIdMap) {
      const clinicId = clinicLocationToIdMap[patientData.clinicLocation];
      if (clinicId) {
        setPrescriptionImage(prescriptionPhotoURLs[clinicId]);
      }
    }
  }, [
    clinicLocationToIdMap,
    patientData.clinicLocation,
    prescriptionPhotoURLs,
  ]);
  // const clinicDetails = doctorDetails.clinics;
  const [imagePrescription, setImagePrescription] = useState(null);
  const captureRef = useRef(null);
  const excludeRef = useRef();
  const navigate = useNavigate();
  const getAllDegree = doctorDetails.degree;
  const handlePrint = () => {
    window.print();
  };
  const uploadPrisciptionPage = async () => {
    if (captureRef.current) {
      if (excludeRef.current) {
        excludeRef.current.style.display = "none";
      }
      try {
        const canvas = await html2canvas(captureRef.current);
        const imgData = canvas.toDataURL("image/png");
        setImagePrescription(imgData);
      } catch (error) {
        console.error("Error capturing image", error);
      }
    }
  };
  if (imagePrescription) {
    const typeMatch = imagePrescription.match(/^data:(image\/\w+);base64,/);
    if (typeMatch) {
      const imageType = typeMatch[1].split("/")[1];

      // Convert base64 to Blob
      const byteCharacters = atob(imagePrescription.split(",")[1]);
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: `image/${imageType}` });

      // Create a file from the Blob
      const filename = `image.${imageType}`;

      const file = new File([blob], filename, { type: `image/${imageType}` });
      // Prepare image data for upload
      const formData = new FormData();
      formData.append("contact", patientData.contact);
      formData.append("image", file);

      try {
        if (!imagePrescription) {
          throw new Error("Please select a file.");
        }
        const response = apiServices.post(
          "authentication",
          "/prescriptionUpload",
          null,
          null,
          formData
        );
        
        if (response) {
          reset();
          navigate(`/uploadDocument/${patientData.contact}`, {
            state: { imagePrescription },
          });
        }
      } catch (error) {
        console.error("Error uploading prescription:", error);
      }
    }
  }
  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
    >
      <div className="relative p-4 w-full max-w-6xl h-full md:h-[99%]">
        <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
            onClick={reset}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close popup</span>
          </button>

          <div className="" ref={captureRef}>
            {prescriptionImage ? (
              <img
                className="w-full h-auto"
                src={`data:image/jpeg;base64,${prescriptionImage}`}
                alt="Prescription"
              />
            ) : (
              <div className="p-5">
                <h5 className="text-lg mb-0.5 font-medium">
                  {doctorDetails.firstName} {doctorDetails.lastName}
                </h5>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  {doctorDetails.specialization}
                </p>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  {getAllDegree}
                </p>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  Location: {patientData.clinicLocation}
                </p>
                <p className="mb-4 text-sm font-normal text-gray-800">
                  Mobile No: {patientData.contact}
                </p>
              </div>
            )}

            <form className="w-full">
              <div className="flex items-center justify-around  pl-4 md:p-2 border-t-2 border-gray-200 rounded-b dark:border-black">
                <p className="text-black  text-sm px-1 py-2.5 text-center ">
                  Patient Name: &nbsp;{patientData.name}
                </p>
                <p className="text-black text-sm px-1 py-2.5 text-center ">
                  Age: &nbsp;{patientData.age}
                </p>
                <p className="text-black text-sm px-2 py-2.5 text-center ">
                  Sex: &nbsp;{patientData.gender}
                </p>
                <p className="text-black text-sm px-2 py-2.5 text-center ">
                  Date: &nbsp;{patientData.appointmentDate}
                </p>
              </div>
              <div class="flex flex-wrap ">
                <div
                  className={`${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-2/3"
                      : ""
                  } `}
                >
                  <section className="text-gray-600 body-font">
                    <div className="container px-5 mx-auto">
                      <div className="flex flex-wrap -m-4">
                        <div className="flex flex-col flex-wrap p-4 w-1/2">
                          <div className="left-3 ">
                            {prescriptionResult.complaint.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900 ">
                                  Complaints
                                </div>
                                {prescriptionResult.complaint.map(
                                  (complaints) => (
                                    <span className="pr-2 pb-2">
                                      {complaints}
                                    </span>
                                  )
                                )}
                              </div>
                            )}
                            {prescriptionResult.diagnosis.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                  Diagnosis
                                </div>
                                {prescriptionResult.diagnosis.map(
                                  (diagnosises) => (
                                    <span className="pr-2 pb-2">
                                      {diagnosises}
                                    </span>
                                  )
                                )}
                              </div>
                            )}
                            <img
                              src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/medicalPriscription.jpg"
                              alt="Example medical"
                              className="w-16 h-13"
                            />
                            {prescriptionResult.medicines.length > 0 && (
                              <div className="mb-4 ">
                                {prescriptionResult.medicines.map((med, i) => (
                                  <div key={i} className="pb-2">
                                    {" "}
                                    <span className="pr-2 pb-2">
                                      {med.name}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.composition}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.dose}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.when}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.frequency}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.duration}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                            {prescriptionResult.test.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                  Test
                                </div>
                                {prescriptionResult.test.map((tests) => (
                                  <span className="pr-2 pb-2">{tests}</span>
                                ))}
                              </div>
                            )}
                            {prescriptionResult.advice.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                  Advice
                                </div>
                                {prescriptionResult.advice.map((advices) => (
                                  <span className="pr-2 pb-2">{advices}</span>
                                ))}
                              </div>
                            )}
                             {formatDateToDDMMYY(selectedDate) !== formatDateToDDMMYY(new Date()) && (
                              <div className="mb-7">
                                <div className="text-lg text-gray-600">
                                  Next Appointment Date :-  <span className="text-gray-900">{formatDateToDDMMYYEPRESCRPITON(selectedDate)}</span>
                                </div>
                             
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="flex flex-col w-1/2 ">
                          <nav class="flex flex-wrap list-none pt-5">
                            {prescriptionResult.height && (
                              <li class=" mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Height:- {prescriptionResult.height} cm
                                </p>
                              </li>
                            )}
                            {prescriptionResult.weight && (
                              <li class=" mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Weight:- {prescriptionResult.weight} kg
                                </p>
                              </li>
                            )}
                            {prescriptionResult.highbp && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Bp:- {prescriptionResult.highbp}/{" "}
                                  {prescriptionResult.lowbp}
                                </p>
                              </li>
                            )}
                            {prescriptionResult.pulse && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Pulse:- {prescriptionResult.pulse} cm
                                </p>
                              </li>
                            )}
                            {prescriptionResult.spo && (
                              <li class=" mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  SPO2:- {prescriptionResult.spo} cm
                                </p>
                              </li>
                            )}
                            {prescriptionResult.temp && (
                              <li class=" mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Temp:- {prescriptionResult.temp} cm
                                </p>
                              </li>
                            )}
                          </nav>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                {doctorDetails?.specialization == "Eye Specialist" && (
                  <EyePrescriptionDownload eyeData={eyeData} />
                )}
              </div>
              <div
                ref={excludeRef}
                className="flex items-center p-1 md:p-5 mb-2 border-gray-200 rounded-b dark:border-gray-600"
              >
                <div className="flex">
                  <button
                    data-modal-hide="default-modal"
                    type="button"
                    onClick={handlePrint}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Print
                  </button>
                  <button
                    onClick={uploadPrisciptionPage}
                    data-modal-hide="default-modal"
                    type="button"
                    className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpresciptionDownload;
