import React, { useState, useEffect, useRef } from "react";
import useData from "../../redux/customHook/useData";
import InvoiceSection from "./invoiceSection";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import apiServices from "../../../Service/apiService";
import docaid from "../../../assets/doc-aid.png"
import reshita from "../../../assets/reshita.jpg"

const Invoice = ({ reset, invoiceDetails,email }) => {
  const { data } = useData();
  const [amount, setAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const invoiceRef = useRef(null)
  useEffect(() => {
    if (invoiceDetails && invoiceDetails.amount) {
      const calculatedAmount = (invoiceDetails.amount * 100) / 118;
      setAmount(calculatedAmount.toFixed(2)); // Ensure two decimal places
      setGst((invoiceDetails.amount - calculatedAmount).toFixed(2)/2);
    }
  }, [invoiceDetails]);

  const handlePrint = () => {
    window.print();
  };
  const paymentId = invoiceDetails?.paymentId || "N/A";
  const orderId = invoiceDetails?.orderId || "N/A";
  const paymentDate = invoiceDetails?.paymentDate || "N/A";
  const totalAmount = invoiceDetails?.amount?.toFixed(2) || "0.00";
  

  const handleSendPDF = async () => {
    if (!invoiceRef.current) return;

    // Temporarily hide elements with "hidden-in-pdf" class
    const elementsToHide = document.querySelectorAll(".hidden-in-pdf");
    elementsToHide.forEach((el) => (el.style.display = "none"));

    try {
      // Convert the external images to Base64
      const fetchImageAsBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      };

      // Fetch images as base64
      const docAidBase64 = await fetchImageAsBase64(
        {docaid}
      );
      const reshitaBase64 = await fetchImageAsBase64(
        {reshita}
      );

      // Embed Base64 into img tags
      const docAidImg = document.querySelector("#docAidImg");
      const reshitaImg = document.querySelector("#reshitaImg");
      if (docAidImg) docAidImg.src = docAidBase64;
      if (reshitaImg) reshitaImg.src = reshitaBase64;

      // Generate canvas
      const canvas = await html2canvas(invoiceRef.current, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);

      // Create and compress PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

      // Convert PDF to Blob
      const pdfBlob = pdf.output("blob");

      // Send the PDF Blob via API
      const formData = new FormData();
      formData.append("file", pdfBlob, "invoice.pdf");

      const response = await apiServices.post(
        "authentication",
        "/email/sendInvoice",
        null,
        { recipientEmail: email },
        formData
      );

      const response2 = await apiServices.post(
        "authentication",
        "/email/sendInvoice",
        null,
        { recipientEmail: "enquiry@doc-aid.in" },
        formData
      );

      if (response.ok && response2.ok) {
        console.log("PDF sent successfully!");
      } else {
        console.error("Failed to send PDF");
      }
    } catch (error) {
      console.error("Error sending PDF:", error);
    } finally {
      // Restore visibility of hidden elements
      elementsToHide.forEach((el) => (el.style.display = ""));
    }
  };

  
  return (
    <div
    id="login-popup"
    tabIndex="-1"
    className="bg-black/50 fixed inset-0 z-50 flex items-center justify-center"
  >
    <div className="relative p-4 w-full max-w-4xl h-full md:h-[99%]">
      <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={reset}
          aria-label="Close popup"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="#c6c7c7"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        <div className="p-5" ref={invoiceRef} >
          <footer className="text-gray-600 body-font">
            <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
              <div className="lg:w-3/4 mb-0">
                <img
                id="docAidImg"
                  alt="Company Logo"
                  className="w-[120px] xl:w-[170px] lg:w-auto mb-0"
                  src={docaid}
                />
              </div>
              <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                <div className="lg:w-3/4 mb-0">
                  <img
                  id="reshitaImg"
                    alt="Company Stamp"
                    className="w-[80px] xl:w-[80px] lg:w-auto mb-0"
                    src={reshita}
                  />
                </div>
              </span>
            </div>
          </footer>

          <section className="text-gray-600 body-font">
            <div className="container mx-auto">
              <div className="flex flex-col sm:flex-row items-start mx-auto">
                <div className="flex-grow  text-md font-medium title-font text-gray-900">
                  <div className="px-3 py-2">
                    <div className="text-gray-900 font-bold">From</div>
                    <div className="text-lg font-bold">M/s Reshita</div>
                    <div className="text-lg">
                      Flat no. 33 Mangaldeep Apartment
                    </div>
                    <div>Patliputra Colony, Patna, 800013</div>
                    <div className="font-bold">GST No. - 10KGMPK1521A1Z2</div>
                  </div>
                </div>
                <div className="flex-shrink-0 border-0 py-2 px-8 rounded text-lg mt-10 sm:mt-0">
                  <div className="text-gray-900 font-bold">Bill to</div>
                  <div>{`${data?.firstName} ${data?.lastName}`}</div>
                  <div>{data?.email}</div>
                  <div>{data?.contact}</div>
                </div>
              </div>
            </div>
          </section>

          <div className="px-5">
            <div className="pt-6">
              <span className="font-bold">Payment Id: </span>{paymentId}
            </div>
            <div className="py-1">
              <span className="font-bold">Order Id: </span>{orderId}
            </div>
            <div className="py-1">
              <span className="font-bold">Invoice No: </span>RE/24-25/{invoiceDetails?.id +1}
            </div>
            <div>
              <span className="font-bold">Payment Date: </span>{paymentDate}
            </div>

            <div className="text-xl font-bold text-red-400 mt-4">
              Your Account Summary
            </div>
            <div className="text-md text-black font-semibold mt-2">
              Subscription Charges
            </div>
            <div className="bg-gray-100 my-5">
              <InvoiceSection label="Previous Balance" value="0.00" />
              <InvoiceSection label="Amount" value={amount} />
              <InvoiceSection label="9% SGST" value={gst} />
              <InvoiceSection label="9% CGST" value={gst} />
              <InvoiceSection label="Total Amount" value={totalAmount} />
            </div>
          </div>
          <div className="flex items-center p-1 md:p-5 mb-2 border-gray-200 rounded-b hidden-in-pdf" >
            <button
              type="button"
              onClick={handlePrint}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Print
            </button>
          <button
            type="button"
            onClick={handleSendPDF}
            className="ml-2 text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Send PDF
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};


export default Invoice;
